<template>
  <div>
    <PageHeader title="Activity Log" sub-title="A log of all activity within Etainabl" category="Company" category-logo="fa-users" />

    <div class="content content-full">
      <div class="mb-3">
        <div class="row">
          <div class="col-md-3">
            <FormGroup id="date" v-model="options.dateRange" label="Date Range" type="dateRangePicker" class="mb-0" @input="onDateInput" />
          </div>
        </div>
      </div>
      <DataTable
        :items="searchResults.data"
        :loading="logsLoading.search"
        :columns="columns"
        :page-size="100"
        :fetch-items="refresh"
        :other-params="otherParams"
        default-sort-key="createdAt"
        :default-sort-direction="-1"
        :pagination="searchResults.pagination"
        :minimum-search-length="3"
        top-pagination
      >
        <template v-slot:createdAt="{ data }">
          <span class="font-w600 no-wrap">{{ data.createdAt | date('Do MMM YYYY HH:mm:ss') }}</span>
        </template>

        <template v-slot:type="{ data }">
          {{ typeMap[data.type] || data.type }}
        </template>

        <template v-slot:userSub="{ data }">
          {{ data.userSub | user(users) }}
        </template>

        <template v-slot:context="{ data }">
          <a class="btn btn-link font-w600 no-wrap" @click="modal.details = data">More Details</a>
        </template>
      </DataTable>
      <ConfirmModal
        :open="!!modal.details"
        title="Log Details"
        lg-size
        hide-submit
        @close="
          () => {
            modal.details = false;
          }
        "
      >
        <p class="font-w600">{{ modal.details.message }}</p>
        <div class="mb-4">
          <PropertyListItem title="Date">{{ modal.details.createdAt | date('Do MMMM YYYY') }}</PropertyListItem>
          <PropertyListItem title="Time">{{ modal.details.createdAt | date('HH:mm:ss') }}</PropertyListItem>
          <PropertyListItem title="Type">{{ typeMap[modal.details.type] || modal.details.type }}</PropertyListItem>
          <PropertyListItem title="User">{{ modal.details.userSub | user(users) }}</PropertyListItem>
          <PropertyListItem v-if="modal.details.attachment?.s3Key" title="Attachment">
            <a href="#" class="font-w600" @click.prevent="onClickDownloadAttachment(modal.details)"
              >Download {{ modal.details.attachment.fileName || modal.details.attachment.s3Key.split('/').pop() }}</a
            >
          </PropertyListItem>
        </div>
        <p class="font-w600 text-muted">Additional Data</p>
        <pre class="pre--dark p-3">{{ modal.details.context }}</pre>
      </ConfirmModal>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import DataTable from '@/components/DataTable';
import FormGroup from '@/components/FormGroup';
import PageHeader from '@/components/PageHeaderDisplay';
import PropertyListItem from '@/components/base/PropertyListItem';
import { downloadFileViaApi } from '@/lib/downloadManager';

export default {
  name: 'CompanyLog',
  components: {
    ConfirmModal,
    DataTable,
    FormGroup,
    PageHeader,
    PropertyListItem
  },
  data() {
    return {
      modal: {
        details: false
      },
      name: null,
      lastParams: {
        data: {
          params: {}
        }
      },
      typeMap: {
        'user-activity': 'User Activity',
        scraper: 'Scraper Run',
        report: 'Report Generated',
        import: 'Manual Data Import',
        'automation-ingest': 'Automation Ingest',
        'data-import': 'Data Import',
        email: 'Email (Received)',
        confirmation: 'Invoice Confirmation',
        delete: 'Data Deletion',
        'email-sent': 'Email (Sent)',
        upload: 'Invoice Upload'
      },
      otherParams: {
        isSystem: false
      },
      options: {
        dateRange: [moment().subtract(1, 'month').toDate(), moment().toDate()]
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: 'company/loadingAction',
      error: 'company/errorAction',
      searchResults: 'log/searchResults',
      users: 'company/users',
      logsLoading: 'log/loadingAction'
    }),
    sortedUsers() {
      return [...this.users].sort((a, b) => a.name.localeCompare(b.name));
    },
    columns() {
      return [
        { name: 'Created At', code: 'createdAt' },
        { name: 'Message', code: 'message', search: true, noSort: true },
        {
          name: 'Type',
          code: 'type',
          search: true,
          searchType: 'dropdown',
          searchOptions: Object.entries(this.typeMap).map(([key, val]) => ({
            label: val,
            value: key
          })),
          searchCode: 'type',
          noSort: true
        },
        {
          name: 'User',
          code: 'userSub',
          search: true,
          searchType: 'dropdown',
          searchOptions: this.sortedUsers.map(user => ({
            label: user.name,
            value: user.userSub
          })),
          noSort: true
        },
        { name: 'Data', code: 'context', noSort: true }
      ];
    }
  },
  async mounted() {
    await this.listUsers({ id: this.$auth.companyId });
  },
  beforeDestroy() {
    this.clearStore();
    this.clearLogStore();
  },
  methods: {
    ...mapActions({
      getLog: 'log/get',
      searchLogs: 'log/search',
      listUsers: 'company/listUsers',
      downloadAttachment: 'log/downloadAttachment'
    }),
    ...mapMutations({
      clearStore: 'company/CLEAR_STORE',
      clearLogStore: 'log/CLEAR_STORE'
    }),
    onClickDownloadAttachment(log) {
      const filename = log.attachment.fileName || log.attachment.s3Key.split('/').pop();
      downloadFileViaApi(filename, this.downloadAttachment, {
        id: log._id
      });
    },
    onDateInput() {
      this.refresh();
    },
    refresh(params) {
      if (params) this.lastParams = params;

      const requestParams = params || this.lastParams;

      this.searchLogs({
        data: {
          ...requestParams.data,
          params: {
            ...requestParams.data.params,
            ...(this.options.dateRange?.length === 2 ? { createdAt: this.options.dateRange.map(d => moment(d).format('YYYY-MM-DD')).join('|') } : {})
          }
        }
      });
    }
  }
};
</script>
